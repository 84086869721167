<template>
  <Default :disable-load-events="isIntranet">
    <template #intranet>
      <div
        v-if="isIntranet"
        class="intranet-header"
      >
        <div>
          <h2>{{ $t('intranet.navHeading', { orgName: primaryOrgName }) }}</h2>
          <div class="auth-info">
            <span
              >{{ $t('intranet.userHeading') }}<strong>&nbsp;{{ $auth.value.user.profile.name }}</strong></span
            >
            <ts-avatar
              size="small"
              height="30px"
              :src="$auth.value.user.profile.avatar"
            />
            <a
              class="logout"
              href="#"
              @click="logout"
              >{{ $t('intranet.logout') }}</a
            >
          </div>
        </div>
      </div>
    </template>
    <slot />
  </Default>
</template>
<script>
import Default from './default.vue';
import { mapState } from 'vuex';
import Avatar from '../components/intranet/dscl/Avatar.vue';
import { useIntranet } from '@/composables/useIntranet';

export default {
  components: { Default, 'ts-avatar': Avatar },
  setup() {
    const { isIntranet } = useIntranet();
    definePageMeta({
      middleware: ['auth', 'intranet-roles'],
    });

    return { isIntranet };
  },
  computed: {
    ...mapState(['globals']),
    primaryOrgName() {
      return this.$store.state.organizations.find((org) => org.primary).name;
    },
  },
  methods: {
    async logout() {
      try {
        this.$store.commit('setNeedsRefresh', true);
        const oidcClient = useOidcClient();
        await oidcClient().removeUser();
        const authCookie = useCookie('auth-token');
        authCookie.value = undefined;
        navigateTo('/intranet/login');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style lang="scss">
@import '../components/global/scss/mixins/breakpoints';

.intranet-header {
  --big-screen-padding: 30px;
  --little-screen-padding: 10px;
  --intranet-nav-height-big: 60px;
  --intranet-nav-height-small: 45px;

  height: var(--intranet-nav-height-big);
  > div {
    color: white;
    position: fixed;
    z-index: 4;
    width: 100%;
    height: var(--intranet-nav-height-big);
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: 16px;
      padding-left: var(--big-screen-padding);
    }
    .auth-info {
      display: flex;
      align-items: center;
      gap: 6px;
      padding-right: var(--big-screen-padding);
      .logout {
        color: #02a0e3;
      }
    }
  }
  @include screen('<=sm') {
    height: var(--intranet-nav-height-small);
    div {
      height: var(--intranet-nav-height-small);
      h2 {
        display: none;
      }
      .auth-info {
        padding-left: var(--little-screen-padding);
      }
    }
  }
}
</style>
